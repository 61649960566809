import {
  BranchResponse,
  ListBranchResponse,
  UUID,
  ViewRequest,
  MergeBranchRequest,
} from '@explo-tech/fido-api';
import { BranchResourceService } from '@explo-tech/fido-api/services/BranchResourceService';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeFidoThunkRequest } from 'utils/thunkUtils';

export const listBranchesThunk = createAsyncThunk<ListBranchResponse, void, { state: ReduxState }>(
  ACTION.FIDO_LIST_BRANCHES,
  async (_, { getState }) => {
    const fido = getState().fido;

    return makeFidoThunkRequest(
      () => BranchResourceService.listBranches(),
      fido.fidoToken ?? '',
      'Error fetching branches',
    );
  },
);

// TODO: Create branch probably shouldn't create a commit, should just accept a view ID?
export const createBranchThunk = createAsyncThunk<
  BranchResponse,
  ViewRequest,
  { state: ReduxState }
>(ACTION.FIDO_CREATE_BRANCH, async (requestBody, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.createBranch(requestBody),
    fido.fidoToken ?? '',
    'Error creating branch',
  );
});

export const getBranchThunk = createAsyncThunk<
  BranchResponse,
  { id: UUID; includeMeta?: boolean },
  { state: ReduxState }
>(ACTION.FIDO_GET_BRANCH, async ({ id, includeMeta = false }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.getBranch(id, includeMeta),
    fido.fidoToken ?? '',
    'Error fetching branch',
  );
});

export const mergeBranchThunk = createAsyncThunk<
  BranchResponse,
  { id: UUID; requestBody: MergeBranchRequest; onSuccess: () => void },
  { state: ReduxState }
>(ACTION.FIDO_MERGE_BRANCH, async ({ id, requestBody, onSuccess }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.mergeBranch(id, requestBody),
    fido.fidoToken ?? '',
    'Error merging branch',
    onSuccess,
  );
});
