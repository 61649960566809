import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CustomerReportAgg, CustomerReportGroupBy } from 'actions/customerReportActions';
import { OPERATION_TYPES } from 'constants/types';
import { InvalidData } from 'pages/ReportBuilder/ReportView/ReportChart/InvalidData';
import {
  customerReportToSharedInstructions,
  cloneRowsIfDate,
} from 'pages/ReportBuilder/utils/visualizationUtils';
import { PieChart } from 'pages/dashboardPage/charts/pieChart';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import { DatasetColumn } from 'types/datasets';

type Props = {
  groupBys?: CustomerReportGroupBy[];
  aggregations?: CustomerReportAgg[];
  reportData: ReportData;
  schema: DatasetColumn[];
  onSelect?: (chartInfo: ChartMenuInfo | null) => void;
};

export const ReportPieChart: FC<Props> = ({
  aggregations,
  reportData: { isLoading, rows },
  groupBys,
  schema,
  onSelect,
}) => {
  const { variables, globalStyleConfig } = useSelector(
    (state: ReportBuilderReduxState) => ({
      variables: state.embeddedReportBuilder.variables,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
    }),
    shallowEqual,
  );

  const instructions = useMemo(
    () => customerReportToSharedInstructions(aggregations, groupBys),
    [aggregations, groupBys],
  );

  // PieChart mutates previewData for date types, but rows is immutable and an error will be thrown if we don't deep copy (real dumb)
  const previewData = useMemo(() => cloneRowsIfDate(groupBys, rows), [groupBys, rows]);

  const missingText = useMemo(
    () => [
      ...(!aggregations?.length ? ['Values'] : []),
      ...(!groupBys?.length ? ['Group by'] : []),
    ],
    [aggregations?.length, groupBys?.length],
  );

  if (missingText.length)
    return (
      <InvalidData operationType={OPERATION_TYPES.VISUALIZE_PIE_CHART_V2}>
        Missing {missingText.join(' and ')}
      </InvalidData>
    );

  return (
    <PieChart
      backgroundColor="#fff"
      dataPanelId=""
      dataPanelProvidedId=""
      donut={false} // Donut is not support currently
      drilldownEntryPoints={{}}
      generalOptions={{ enableRawDataDrilldown: !!onSelect }}
      globalStyleConfig={globalStyleConfig}
      instructions={instructions}
      loading={isLoading}
      operationType={OPERATION_TYPES.VISUALIZE_PIE_CHART_V2}
      previewData={previewData}
      schema={schema}
      setChartMenu={onSelect || noOp}
      variables={variables}
    />
  );
};

const noOp = () => null;
