import * as types from 'constants/types';
import { TIME_FORMATS } from 'utils/localizationUtils';

export const EMPTY_FILTER_CLAUSE: types.FilterClause = {
  filterColumn: undefined,
  filterOperation: undefined,
  filterValue: undefined,
};

export enum DATE_RELATIVE_OPTION {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export const DATE_RELATIVE_OPTIONS_BY_ID: Record<
  DATE_RELATIVE_OPTION,
  { id: DATE_RELATIVE_OPTION; name: string }
> = {
  DAYS: { id: DATE_RELATIVE_OPTION.DAYS, name: 'Days' },
  WEEKS: { id: DATE_RELATIVE_OPTION.WEEKS, name: 'Weeks' },
  MONTHS: { id: DATE_RELATIVE_OPTION.MONTHS, name: 'Months' },
  YEARS: { id: DATE_RELATIVE_OPTION.YEARS, name: 'Years' },
};

export const DATE_RELATIVE_OPTIONS = Object.values(DATE_RELATIVE_OPTIONS_BY_ID);

export const DATE_DISPLAY_FORMAT_TO_DATE_FORMAT = {
  [types.DateDisplayFormat.NORMAL]: TIME_FORMATS['MM/DD/YYYY'],
  [types.DateDisplayFormat.NUMERIC_LONG]: TIME_FORMATS['DD-MM-YYYY'],
  [types.DateDisplayFormat.NUMERIC_SHORT]: TIME_FORMATS['MM-DD-YY'],
  [types.DateDisplayFormat.VERBAL_LONG]: TIME_FORMATS['MMMM D, YYYY'],
  [types.DateDisplayFormat.VERBAL_SHORT]: TIME_FORMATS['MMM D, YYYY'],
  [types.DateDisplayFormat.NUMERIC_SHORT_TIMESTAMP]: TIME_FORMATS['MM-dd-yy (HH:mm:ss)'],
  [types.DateDisplayFormat.DATE_TIMESTAMP]: TIME_FORMATS['DD/MM/YYYY (HH:mm:ss)'],
  [types.DateDisplayFormat.RELATIVE_TO_NOW]: TIME_FORMATS['Relative to Now'],
  [types.DateDisplayFormat.CUSTOM]: '',
};

export const DEFAULT_GRADIENT: types.GradientShape = {
  hue1: '#57BB8A',
  hue2: '#FFD666',
  hue3: '#E67C73',
};

enum DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES {
  SMALL = 780,
  MEDIUM = 1150,
  LARGE = 1280,
}

export const datasetEditorShowsDatasetsAsDropdown = (pageWidth: number) => {
  return (
    DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.SMALL <= pageWidth &&
    pageWidth <= DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.MEDIUM
  );
};

export const datasetEditorNotShown = (pageWidth: number) => {
  return pageWidth < DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.SMALL;
};

export const datasetEditorShowsDatasetsAsList = (pageWidth: number) => {
  return pageWidth > DATASET_EDITOR_BREAKPOINT_SCREEN_SIZES.MEDIUM;
};
