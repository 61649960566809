import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import {
  CreateChildDashboardTemplateData,
  Dashboard,
  createChildDashboardTemplate,
  createDrilldownEntryPoint,
} from 'actions/dashboardActions';
import { Button, Input, Label, Popover, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { ChartColumnInfo } from 'constants/types';
import { ColumnTargetRow } from 'pages/dashboardPage/LayersPanel/ColumnTargetRow';
import { saveResourceConfig } from 'reducers/thunks/resourceSaveThunks';
import { DataPanel, ResourcePageType } from 'types/exploResource';

import {
  MAX_CHILD_DASHBOARDS_PER_PARENT,
  MAX_DASHBOARD_HIERARCHY_DEPTH,
  VALID_DRILLDOWN_OPERATION_TYPES,
} from './constants';

type Props = {
  parentDashboard: Dashboard;
  currentUserId: number;
  dataPanelsById: Record<string, DataPanel>;
  parentDashboardDepth: number;
};

export const AddNewLayerPopover: FC<Props> = ({
  parentDashboard,
  currentUserId,
  dataPanelsById,
  parentDashboardDepth,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [selectedDataPanelId, setSelectedDataPanelId] = useState<string>();
  const [selectedEntryColumns, setSelectedEntryColumns] = useState<ChartColumnInfo[]>([]);

  const dataPanels = useMemo(
    () =>
      Object.values(dataPanelsById).filter((dataPanel: DataPanel) =>
        VALID_DRILLDOWN_OPERATION_TYPES.has(dataPanel.visualize_op.operation_type),
      ),
    [dataPanelsById],
  );

  const selectedDataPanelColumnInfos = useMemo(() => {
    if (selectedDataPanelId) {
      const selectedDataPanel = dataPanelsById[selectedDataPanelId];
      return getDrilldownColumnInfosForSelectedDataPanel(selectedDataPanel);
    }
    return {};
  }, [selectedDataPanelId, dataPanelsById]);

  const onSourceDatasetColumnChecked = useCallback(
    (checked: boolean, columnInfo: ChartColumnInfo) => {
      if (checked) {
        setSelectedEntryColumns([...selectedEntryColumns, columnInfo]);
      } else {
        setSelectedEntryColumns(
          selectedEntryColumns.filter((selectedColumnInfo) => selectedColumnInfo !== columnInfo),
        );
      }
    },
    [selectedEntryColumns],
  );

  return (
    <Popover
      className={sprinkles({ padding: 'sp2' })}
      trigger={
        <Button
          disabled={isAddLayerButtonDisabled(parentDashboard, parentDashboardDepth)}
          icon="plus"
        />
      }>
      <div className={sprinkles({ heading: 'h2', marginY: 'sp1' })}>New View</div>
      <Label htmlFor="title-input">Title</Label>
      <Input id="title-input" onChange={setTitle} value={title} />
      <Select
        className={sprinkles({ marginY: 'sp1' })}
        label="Chart"
        onChange={(selectedValue) => setSelectedDataPanelId(selectedValue)}
        selectedValue={selectedDataPanelId}
        values={createDataPanelSelectOptions(dataPanels)}
      />
      {Object.values(selectedDataPanelColumnInfos).map((columnInfo) => {
        return (
          <ColumnTargetRow
            columnInfo={columnInfo}
            key={columnInfo.name}
            onColumnChecked={onSourceDatasetColumnChecked}
          />
        );
      })}
      <Button
        className={sprinkles({ padding: 'sp2', marginTop: 'sp1' })}
        disabled={isCreateButtonDisabled(title, selectedDataPanelId, selectedEntryColumns)}
        onClick={() => {
          if (!selectedDataPanelId) {
            return;
          }

          dispatch(
            createChildDashboardTemplate(
              {
                id: currentUserId,
                postData: {
                  name: title.trim(),
                  parent_dashboard_id: parentDashboard.id,
                  user_id: currentUserId,
                },
              },
              (data: CreateChildDashboardTemplateData) => {
                dispatch(
                  createDrilldownEntryPoint({
                    sourceDashboardId: parentDashboard.id,
                    sourceDataPanelId: selectedDataPanelId,
                    sourceColumns: selectedEntryColumns,
                    entryPointId: uuidv4(),
                    destinationDashboardId: data.new_child_dashboard_template.id,
                  }),
                );
                // Dispatch a direct save after creating the drilldown entry point. Firing off a
                // save event doesn't allow the update to the data panel to persist since the
                // client side routing that follows deletes the save event listener.
                dispatch(saveResourceConfig(ResourcePageType.EXPLORE, parentDashboard.id));
                history.push(ROUTE_PROVIDERS.DASHBOARD(`${data.new_child_dashboard_template.id}`));
              },
            ),
          );
        }}>
        Create
      </Button>
    </Popover>
  );
};

const isAddLayerButtonDisabled = (
  parentDashboard: Dashboard,
  parentDashboardDepth: number,
): boolean =>
  parentDashboard.childDashboardIds.length >= MAX_CHILD_DASHBOARDS_PER_PARENT ||
  parentDashboardDepth >= MAX_DASHBOARD_HIERARCHY_DEPTH;

const isCreateButtonDisabled = (
  title: string,
  selectedDataPanelId: string | undefined,
  selectedEntryColumns: ChartColumnInfo[],
): boolean => title.trim().length <= 0 || !selectedDataPanelId || selectedEntryColumns.length <= 0;

const createDataPanelSelectOptions = (dataPanels: DataPanel[]): SelectItems<string> => {
  return dataPanels.map((dataPanel) => ({
    label:
      dataPanel.visualize_op.generalFormatOptions?.headerConfig?.title || dataPanel.provided_id,
    value: dataPanel.id,
    icon: 'table',
  }));
};

const getDrilldownColumnInfosForSelectedDataPanel = (
  dataPanel: DataPanel,
): Record<string, ChartColumnInfo> => {
  const visualizeOperation = dataPanel.visualize_op;
  if (VALID_DRILLDOWN_OPERATION_TYPES.has(visualizeOperation.operation_type)) {
    const twoDimensionChartInstructions = visualizeOperation.instructions.V2_TWO_DIMENSION_CHART;
    if (!twoDimensionChartInstructions) {
      return {};
    }

    const drilldownColumnInfos: Record<string, ChartColumnInfo> = {};
    const categoryColumn = twoDimensionChartInstructions.categoryColumn;
    if (categoryColumn?.column) {
      drilldownColumnInfos[categoryColumn?.column.friendly_name || ''] = categoryColumn?.column;
    }

    const colorColumns = twoDimensionChartInstructions.colorColumnOptions;
    if (colorColumns) {
      colorColumns.forEach((colorColumn) => {
        if (!colorColumn.column) {
          return;
        }
        drilldownColumnInfos[colorColumn.column.friendly_name || ''] = colorColumn.column;
      });
    }

    return drilldownColumnInfos;
  }
  return {};
};
