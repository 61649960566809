import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardElementConfig,
} from 'types/dashboardTypes';
import { isValidColumnTypeForOperation } from 'utils/filterOperations';

export class DashboardEditConfigReducerMutator {
  static updateElement(
    element: DashboardElement,
    newElementConfig?: DashboardElementConfig,
    newElementType?: DASHBOARD_ELEMENT_TYPES,
  ) {
    if (newElementConfig) {
      element.config = newElementConfig;
    }
    if (!newElementType) {
      return;
    }

    if (
      element.config.operator &&
      !isValidColumnTypeForOperation(element.config.operator, newElementType)
    ) {
      element.config.operator = undefined;
    }

    element.element_type = newElementType;
  }
}
