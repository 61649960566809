import { STRING } from 'constants/dataConstants';
import { SelectedDropdownInputItem } from 'constants/types';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardElementConfig,
  SwitchElementConfig,
} from 'types/dashboardTypes';
import {
  FilterOperator,
  FILTER_OPERATORS,
  FILTER_OPERATOR_TYPES_BY_ID,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NUMBER,
  FILTER_OPS_STRING,
} from 'types/filterOperations';

export const getOperatorOptionsForFilter = (
  elementType: DASHBOARD_ELEMENT_TYPES,
  elementConfig: DashboardElementConfig,
): SelectedDropdownInputItem[] => {
  let allowedSet: Set<FilterOperator>;
  switch (elementType) {
    case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      allowedSet = FILTER_OPS_DATE_PICKER;
      break;
    case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
      allowedSet = FILTER_OPS_DATE_RANGE_PICKER;
      break;
    case DASHBOARD_ELEMENT_TYPES.SWITCH: {
      const switchValueType = (elementConfig as SwitchElementConfig).switchValueType;
      allowedSet = switchValueType === STRING ? FILTER_OPS_STRING : FILTER_OPS_NUMBER;
      break;
    }
    case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
      allowedSet = FILTER_OPS_STRING;
      break;
    case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
    case DASHBOARD_ELEMENT_TYPES.TOGGLE: {
      const items: SelectedDropdownInputItem[] = [{ id: '', name: 'STRINGS', isTitle: true }];
      addOperatorsToList(FILTER_OPS_STRING, items);
      items.push({ id: '', name: 'NUMBERS', isTitle: true });
      addOperatorsToList(FILTER_OPS_NUMBER, items);
      items.push({ id: '', name: 'BOOLEAN', isTitle: true });
      const booleanIs = FILTER_OPERATOR_TYPES_BY_ID[FilterOperator.BOOLEAN_IS];
      items.push({ id: booleanIs.id, name: booleanIs.selectionValue });
      return items;
    }
    case DASHBOARD_ELEMENT_TYPES.MULTISELECT: {
      const stringIsIn = FILTER_OPERATOR_TYPES_BY_ID[FilterOperator.STRING_IS_IN];
      const numberIsIn = FILTER_OPERATOR_TYPES_BY_ID[FilterOperator.NUMBER_IS_IN];
      const stringIsNotIn = FILTER_OPERATOR_TYPES_BY_ID[FilterOperator.STRING_IS_NOT_IN];
      const numberIsNotIn = FILTER_OPERATOR_TYPES_BY_ID[FilterOperator.NUMBER_IS_NOT_IN];
      return [
        { id: '', name: 'STRINGS', isTitle: true },
        { id: stringIsIn.id, name: stringIsIn.selectionValue },
        { id: stringIsNotIn.id, name: stringIsNotIn.selectionValue },
        { id: '', name: 'NUMBERS', isTitle: true },
        { id: numberIsIn.id, name: numberIsIn.selectionValue },
        { id: numberIsNotIn.id, name: numberIsNotIn.selectionValue },
      ];
    }
    default:
      return [];
  }

  return FILTER_OPERATORS.reduce<SelectedDropdownInputItem[]>((acc, op) => {
    if (allowedSet.has(op.id)) acc.push({ id: op.id, name: op.selectionValue });
    return acc;
  }, []);
};

const addOperatorsToList = (operators: Set<FilterOperator>, items: SelectedDropdownInputItem[]) => {
  Array.from(operators).forEach((opId) => {
    const op = FILTER_OPERATOR_TYPES_BY_ID[opId];
    items.push({ id: op.id, name: op.selectionValue });
  });
};

export const isValidOperationForFilter = (
  operator: FilterOperator,
  filterType: DASHBOARD_ELEMENT_TYPES,
): boolean => {
  switch (filterType) {
    case DASHBOARD_ELEMENT_TYPES.DATEPICKER:
      return FILTER_OPS_DATE_PICKER.has(operator);
    case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER:
      return FILTER_OPS_DATE_RANGE_PICKER.has(operator);
    case DASHBOARD_ELEMENT_TYPES.SWITCH:
      return FILTER_OPS_STRING.has(operator);
    case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
      return FILTER_OPS_STRING.has(operator);
    case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
      return FILTER_OPS_MULTISELECT.has(operator);
    case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
    case DASHBOARD_ELEMENT_TYPES.TOGGLE:
      return (
        FILTER_OPS_STRING.has(operator) ||
        FILTER_OPS_NUMBER.has(operator) ||
        operator === FilterOperator.BOOLEAN_IS
      );
  }
  return false;
};

export const isValidColumnTypeForOperation = (
  operator: FilterOperator | undefined,
  columnType: string,
) => {
  if (!operator) return false;
  const operatorInfo = FILTER_OPERATOR_TYPES_BY_ID[operator];
  return operatorInfo.supported_column_types.has(columnType);
};
