import {
  CreateCommitRequest,
  CreateCommitResponse,
  CommitResourceService,
} from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeFidoThunkRequest } from 'utils/thunkUtils';

export const createCommitThunk = createAsyncThunk<
  CreateCommitResponse,
  { branch: string; body: CreateCommitRequest },
  { state: ReduxState }
>(ACTION.FIDO_CREATE_COMMIT, async ({ branch, body }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => CommitResourceService.createCommit(branch, body),
    fido.fidoToken ?? '',
    'Error saving your view',
  );
});
