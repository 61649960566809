import Color from 'color';
import Highcharts from 'highcharts';
import { DateTime } from 'luxon';
import { FC, useCallback, useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { IconButton, Select, sprinkles, vars } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { ChartTooltip, EmbedSpinner } from 'components/embed';
import { MONTH_SUFFIX, YEAR_SUFFIX } from 'constants/dashboardConstants';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  GradientPointOptions,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { TEXT_SIZE_OFFSET_MAP } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { getFontFamilyName } from 'globalStyles/utils';
import { DrilldownChart } from 'pages/dashboardPage/charts/shared/drilldownChart';
import { updateVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { getHeatmapStopPoint } from 'utils/gradientUtils';

import { NeedsConfigurationPanel } from '../needsConfigurationPanel';

import {
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
} from './utils';
import { getStops } from './utils/heatMapUtils';
import { DEFAULT_Y_AXIS_FORMAT_INDEX } from './utils/multiYAxisUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

const OPERATION_TYPE = OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP;
type SeriesOptions = Highcharts.SeriesHeatmapOptions;

const YEAR_OFFSET = 30;
const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type DateInfo = {
  selectedMonth: number;
  selectedYear: number;
  firstDayIndex: number;
  numWeeks: number;
  totalMonthDays: number;
};

type Props = {
  instructions: V2TwoDimensionChartInstructions | undefined;
  loading: boolean;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  globalStyleConfig: GlobalStyleConfig;
  variables: DashboardVariableMap;
  dataPanelProvidedId: string;
  dataPanelTemplateId: string;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  schema: DatasetSchema;
  previewData: Record<string, string | number>[];
  processString: (s: string) => string;
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
};

export const CalendarHeatmap: FC<Props> = ({
  instructions,
  loading,
  generalOptions,
  globalStyleConfig,
  variables,
  dataPanelProvidedId,
  dataPanelTemplateId,
  datasetData,
  datasetNamesToId,
  schema,
  previewData,
  processString,
  drilldownEntryPoints,
}) => {
  const drilldownRef = useRef<HTMLDivElement>(null);

  const { aggColName, xAxisColName } = useMemo(
    () =>
      schema?.length > 0
        ? getColorColNames(schema, OPERATION_TYPE)
        : { aggColName: undefined, xAxisColName: undefined },
    [schema],
  );

  const heatMapFormat = instructions?.chartSpecificFormat?.heatMap;
  const isInitialLoading =
    !schema?.length || !instructions?.categoryColumn || !instructions?.aggColumns?.length;

  // Get all of the date info derived from the month and year saved in variables
  const yearKey = dataPanelProvidedId + YEAR_SUFFIX;
  const monthKey = dataPanelProvidedId + MONTH_SUFFIX;
  const currentDate = new Date();
  const yearVariable = Number(variables?.[yearKey]);
  const monthVariable = Number(variables?.[monthKey]);
  const selectedYear = isNaN(yearVariable) ? currentDate.getFullYear() : yearVariable;
  const selectedMonth = isNaN(monthVariable) ? currentDate.getMonth() : monthVariable;

  const dateData: DateInfo = useMemo(() => {
    const firstDayIndex = new Date(selectedYear, selectedMonth).getDay();
    const totalMonthDays = DateTime.fromObject({
      year: selectedYear,
      month: selectedMonth + 1,
    }).endOf('month').day;

    return {
      selectedMonth,
      selectedYear,
      totalMonthDays,
      firstDayIndex,
      numWeeks: Math.ceil((firstDayIndex + totalMonthDays) / WEEKDAYS.length),
    };
  }, [selectedYear, selectedMonth]);

  const instructionsNeedConfiguration = useMemo(
    () => !isTwoDimVizInstructionsReadyToDisplay(instructions, OPERATION_TYPE),
    [instructions],
  );

  const seriesData = useMemo(
    () =>
      isInitialLoading
        ? { series: [] }
        : transformData(
            previewData,
            xAxisColName,
            aggColName,
            dateData,
            loading,
            heatMapFormat?.nullMissingValues,
          ),
    [
      previewData,
      xAxisColName,
      aggColName,
      dateData,
      loading,
      isInitialLoading,
      heatMapFormat?.nullMissingValues,
    ],
  );

  const gradientStops = useMemo(() => {
    const getFloat = (
      opts: GradientPointOptions | undefined,
      min: number,
      max: number,
      defaultFloat: number,
    ) =>
      getHeatmapStopPoint(opts, min, max, defaultFloat, variables, datasetNamesToId, datasetData);

    return getStops(heatMapFormat, aggColName, globalStyleConfig, previewData, getFloat);
  }, [
    heatMapFormat,
    aggColName,
    globalStyleConfig,
    previewData,
    datasetNamesToId,
    datasetData,
    variables,
  ]);

  const chartOptions = useMemo<Highcharts.Options>(() => {
    const backgroundColor = globalStyleConfig.container.fill;
    const yAxisFormat = instructions?.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX];
    const decimalPlaces = yAxisFormat?.decimalPlaces;
    const formatId = yAxisFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id;
    const bodyText = globalStyleConfig.text.overrides.body;
    const primaryFont = globalStyleConfig.text.primaryFont;
    const smallHeadingText = globalStyleConfig?.text.overrides.smallHeading;
    const textSize = globalStyleConfig.text.textSize;

    const aggCol = instructions?.aggColumns?.[0];
    const aggColTooltipName =
      (aggCol?.column.friendly_name
        ? processString(aggCol?.column.friendly_name)
        : aggCol
        ? getColDisplayText(aggCol)
        : aggColName) ?? '';
    return {
      chart: { type: 'heatmap', backgroundColor },
      ...seriesData,
      title: sharedTitleConfig,
      plotOptions: {
        series: {
          dataLabels: [
            {
              allowOverlap: true,
              enabled: heatMapFormat?.showCellLabels,
              style: {
                textOutline: 'none',
                fontSize: `${bodyText?.size || textSize + TEXT_SIZE_OFFSET_MAP['body']}px`,
                fontWeight: 'normal',
                fontFamily: primaryFont && getFontFamilyName(bodyText?.font || primaryFont),
              },
              useHTML: true,
              formatter: function () {
                // because we can't access any of the custom point values here, we can use the name to tell if a point is a valid date
                const value =
                  this.point.name?.length && !loading ? this.point?.value ?? null : null;
                if ((heatMapFormat?.hideZeros && value === 0) || value === null) return;
                const color = new Color(this.point.color).isDark()
                  ? vars.colors.white
                  : vars.colors.black;
                return `<span style="color: ${color};">
                    ${formatValue({
                      value,
                      decimalPlaces,
                      formatId,
                      hasCommas: true,
                    })}
                  </span>`;
              },
            },
            {
              enabled: true,
              align: 'left',
              verticalAlign: 'top',
              format: '{#unless point.custom.empty}{point.custom.monthDay}{/unless}',
              backgroundColor: backgroundColor,
              padding: 4,
              style: {
                textOutline: 'none',
                ...getLabelStyle(globalStyleConfig, 'primary'),
                opacity: 0.8,
                fontWeight: 'bold',
              },
              x: 1,
              y: 1,
            },
          ],
        },
      },
      colorAxis: {
        stops: gradientStops,
        startOnTick: false,
        endOnTick: false,
        marker: { color: globalStyleConfig.base.actionColor.default },
      },
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          const value = this.point.value;
          // Don't show tooltips for null values
          if (value === null) return null;
          return ReactDOMServer.renderToStaticMarkup(
            <ChartTooltip
              globalStyleConfig={globalStyleConfig}
              header={this.point?.name}
              points={[
                {
                  color: String(this.point.color),
                  name: aggColTooltipName,
                  value: value || 0,
                  format: { decimalPlaces, formatId },
                },
              ]}
            />,
          );
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      yAxis: {
        min: 0,
        max: dateData.numWeeks,
        accessibility: {
          description: 'weeks',
        },
        visible: false,
      },
      xAxis: {
        categories: WEEKDAYS,
        opposite: true,
        lineWidth: 26,
        lineColor: backgroundColor,
        offset: 13,
        labels: {
          rotation: 0,
          y: 5,
          style: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: smallHeadingText?.color || globalStyleConfig?.text.secondaryColor,
            fontSize: `${
              smallHeadingText?.size || textSize + TEXT_SIZE_OFFSET_MAP['smallHeading']
            }px`,
            fontFamily: primaryFont && getFontFamilyName(smallHeadingText?.font || primaryFont),
          },
        },
      },
    };
  }, [
    aggColName,
    dateData.numWeeks,
    globalStyleConfig,
    gradientStops,
    heatMapFormat?.hideZeros,
    heatMapFormat?.showCellLabels,
    instructions?.aggColumns,
    instructions?.legendFormat,
    instructions?.yAxisFormats,
    loading,
    processString,
    seriesData,
  ]);

  if (instructionsNeedConfiguration || !instructions) {
    return (
      <NeedsConfigurationPanel
        fullHeight
        instructionsNeedConfiguration={instructionsNeedConfiguration}
      />
    );
  }

  return (
    <div className={sprinkles({ flexItems: 'column', parentContainer: 'fill' })}>
      <MonthYearDatePicker
        currentYear={currentDate.getFullYear()}
        monthKey={monthKey}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        showLoadingSpinner={loading && !isInitialLoading}
        yearKey={yearKey}
      />

      {isInitialLoading ? (
        <div className={sprinkles({ flexItems: 'center', parentContainer: 'fill' })}>
          <EmbedSpinner />
        </div>
      ) : (
        <DrilldownChart
          chartOptions={chartOptions}
          customMenuOptions={
            generalOptions?.customMenu?.enabled
              ? generalOptions?.customMenu?.menuOptions
              : undefined
          }
          dataPanelId={dataPanelTemplateId}
          drilldownEntryPoints={drilldownEntryPoints}
          drilldownRef={drilldownRef}
          instructions={instructions}
          underlyingDataEnabled={generalOptions?.enableRawDataDrilldown}
        />
      )}
    </div>
  );
};

type MonthYearProps = {
  monthKey: string;
  yearKey: string;
  currentYear: number;
  selectedMonth: number;
  selectedYear: number;
  showLoadingSpinner: boolean;
};

const MonthYearDatePicker: FC<MonthYearProps> = ({
  monthKey,
  yearKey,
  currentYear,
  selectedMonth,
  selectedYear,
  showLoadingSpinner,
}) => {
  const dispatch = useDispatch();

  const monthDropdownOptions: SelectItems<string> = MONTHS.map((monthName, index) => ({
    label: monthName,
    value: index.toString(),
  }));

  // Get 30 years in the past and 30 years in the future to populate the year dropdown
  const yearDropdownOptions: SelectItems<string> = useMemo(
    () =>
      [...Array(YEAR_OFFSET * 2 + 1).keys()].map((yearOffset) => {
        const year = currentYear - YEAR_OFFSET + yearOffset;
        return {
          value: year.toString(),
        };
      }),
    [currentYear],
  );

  const updateDate = useCallback(
    (month?: number, year?: number) => {
      if (month != null) dispatch(updateVariableThunk({ varName: monthKey, newValue: month }));

      if (year != null && year >= currentYear - YEAR_OFFSET && year <= currentYear + YEAR_OFFSET)
        dispatch(updateVariableThunk({ varName: yearKey, newValue: year }));
    },
    [currentYear, dispatch, monthKey, yearKey],
  );

  return (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
      <IconButton
        name="chevron-left"
        onClick={() => {
          const newMonth = selectedMonth - 1;
          updateDate(wrapAroundMod(newMonth, 12), newMonth < 0 ? selectedYear - 1 : undefined);
        }}
      />
      <Select
        onChange={(newValue) => {
          const month = parseInt(newValue);
          if (!isNaN(month)) updateDate(month);
        }}
        selectedValue={selectedMonth.toString()}
        values={monthDropdownOptions}
      />
      <Select
        onChange={(newValue) => {
          const year = parseInt(newValue);
          if (!isNaN(year)) updateDate(undefined, year);
        }}
        selectedValue={selectedYear.toString()}
        values={yearDropdownOptions}
      />
      <IconButton
        name="chevron-right"
        onClick={() => {
          const newMonth = selectedMonth + 1;
          updateDate(wrapAroundMod(newMonth, 12), newMonth > 11 ? selectedYear + 1 : undefined);
        }}
      />
      {showLoadingSpinner ? <EmbedSpinner size="md" /> : null}
    </div>
  );
};

const wrapAroundMod = (num: number, divisor: number) => ((num % divisor) + divisor) % divisor;

type TransformedData = { series: SeriesOptions[] };

const transformData = (
  rows: Record<string, string | number>[] | undefined,
  xAxisColName: string | undefined,
  aggColName: string | undefined,
  dateData: DateInfo,
  loading: boolean,
  nullMissingValues?: boolean,
): TransformedData => {
  const numDaysInWeek = WEEKDAYS.length;
  const { selectedMonth, selectedYear, numWeeks, firstDayIndex, totalMonthDays } = dateData;

  const getXCoord = (day: number): number => {
    return (firstDayIndex + (day - 1)) % numDaysInWeek;
  };

  const getYCoord = (day: number): number => {
    return Math.floor((firstDayIndex + (day - 1)) / numDaysInWeek);
  };

  const isValidDate = (index: number): boolean => {
    return index >= firstDayIndex && index < firstDayIndex + totalMonthDays;
  };

  const chartData: {
    x: number;
    y: number;
    value: number | null;
    name: string | undefined;
    custom: { empty: boolean; monthDay: number | undefined };
  }[] = [];

  // fill out the entire grid with empty data for the blank calendar state
  // all cells for dates outside of the selected month are always null
  for (let yIndex = 0; yIndex < numWeeks; yIndex++) {
    for (let xIndex = 0; xIndex < numDaysInWeek; xIndex++) {
      const arrayIndex = yIndex * numDaysInWeek + xIndex;
      const valid = isValidDate(arrayIndex);
      const day = arrayIndex - firstDayIndex + 1;
      chartData.push({
        x: xIndex,
        y: numWeeks - yIndex,
        value: valid && !nullMissingValues && !loading ? 0 : null,
        name: valid ? new Date(selectedYear, selectedMonth, day).toDateString() : '',
        custom: {
          empty: !valid,
          monthDay: valid ? arrayIndex - firstDayIndex + 1 : undefined,
        },
      });
    }
  }

  // when not loading, fill in the actual data (which is not guaranteed to be continuous or in order)
  if (rows && rows.length > 0 && xAxisColName !== undefined && aggColName !== undefined && !loading)
    rows.forEach((row) => {
      const day = row[xAxisColName] as number;
      const arrayIndex = getYCoord(day) * numDaysInWeek + getXCoord(day);
      chartData[arrayIndex] = {
        ...chartData[arrayIndex],
        value: getAxisNumericalValue(row[aggColName]),
      };
    });

  return { series: [{ data: chartData, type: 'heatmap', nullColor: 'transparent' }] };
};
