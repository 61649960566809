import { ViewChange } from '@explo-tech/fido-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createBranchThunk, listBranchesThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { createCommitThunk } from 'reducers/thunks/fidoThunks/commitThunks';
import * as RD from 'remotedata';

// We use dashboard ID's as branch names
type DashboardId = string;

type ViewId = string;

interface DatasetVersionsReducerState {
  listBranchesStatus: RD.ResponseData<null>;
  createBranchStatus: RD.ResponseData<null>;
  createCommitStatus: RD.ResponseData<ViewChange[]>;
  branches: Partial<Record<DashboardId, ViewId[]>>; // Branches are stored as a map of dashboard ID to view ID's
}

const initialState: DatasetVersionsReducerState = {
  branches: {},
  listBranchesStatus: RD.Idle(),
  createBranchStatus: RD.Idle(),
  createCommitStatus: RD.Idle(),
};

const datasetVersionsSlice = createSlice({
  name: 'datasetVersions',
  initialState,
  reducers: {
    deleteBranch(state, { payload }: PayloadAction<string>) {
      delete state.branches[payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listBranchesThunk.fulfilled, (state, { payload }) => {
        state.branches = {};
        payload.branches.forEach((branch) => (state.branches[branch.name] = []));
      })
      .addCase(listBranchesThunk.pending, (state) => {
        state.listBranchesStatus = RD.Loading();
      })
      .addCase(listBranchesThunk.rejected, (state) => {
        state.listBranchesStatus = RD.Error('Ooops');
      })
      .addCase(createBranchThunk.fulfilled, (state, { payload }) => {
        state.branches[payload.branch.name] = [];
      })
      .addCase(createBranchThunk.pending, (state) => {
        state.createBranchStatus = RD.Loading();
      })
      .addCase(createBranchThunk.rejected, (state) => {
        state.createBranchStatus = RD.Error('Ooops');
      })
      .addCase(createCommitThunk.fulfilled, (state, { payload }) => {
        state.createCommitStatus = RD.Success(payload.changes);
      })
      .addCase(createCommitThunk.pending, (state) => {
        state.createCommitStatus = RD.Loading();
      })
      .addCase(createCommitThunk.rejected, (state) => {
        state.createCommitStatus = RD.Error('Ooops');
      });
  },
});

export const datasetVersionsReducer = datasetVersionsSlice.reducer;

export const { deleteBranch } = datasetVersionsSlice.actions;
